import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useListAmount } from "../../../logic/useListAmount";
import Style from "./GiftCardsValue.module.scss";
import { useTranslation } from "react-i18next";
import giftCardImg from "./card_img.svg";

const GiftCardsValue = ({ formik, langSlug, basicData }) => {
  const { listAmountData } = useListAmount();
  const [inputValue, setInputValue] = useState("");
  const [inputActive, setInputActive] = useState("");

  const inputValueClick = (e) => {
    setInputValue(e.target.value);
    setInputActive(e.target.value);
  };

  const { t } = useTranslation(["common"]);
  const strippedString = listAmountData?.max_limit?.split(".")?.[0];
  const length = strippedString?.length;

  return (
    <section className={Style.gift_cards_value}>
      <h1 className={`${Style.title} mb-0`}>{t("CHOOSE_GIFT_CARD")}</h1>
      <div className={Style.enter_amount}>
        <div className="row">
          <div className="col-12 col-lg-6 col-xxl-5">
            <label htmlFor="amount" className={Style.title_label}>
              {t("enter_amount")}
            </label>
            <Form.Group className="form-group text-start text-black" controlId="amount">
              <Form.Control
                type="text"
                name="amount"
                value={parseInt(formik.values.amount) || inputValue}
                maxLength={length}
                placeholder={t("Enter_Amount")}
                onBlur={formik.handleBlur}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => {
                  formik.handleChange(e);
                  setInputActive(e.target.value);
                  setInputValue(e.target.value);
                }}
              />
            </Form.Group>
            {formik.errors.amount && formik.touched.amount ? (
              <div className={`${Style.error_msg} formikError`}>{formik.errors.amount}</div>
            ) : null}
            {formik.values.amount >= 50 && !formik.errors.amount && basicData?.status == 1 && (
              <p className={`${Style.verified_msg} mb-0`}>
                {basicData?.validation_message}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={Style.select_amount_wrapper}>
        <label htmlFor="" className={Style.select_amount_title}>
          {t("Select_amount")}
        </label>
        <div className={Style.select_amount}>
          {listAmountData?.amounts?.map((value, index) => {
            return (
              <button
                type="button"
                className={
                  inputActive === value.amount
                    ? ` ${Style.select_amount_btn} ${Style.active}`
                    : ` ${Style.select_amount_btn} ${
                        formik.values.amount == value.amount && Style.active
                      }`
                }
                value={value.amount}
                onClick={(e) => {
                  if (e.target.value >= value?.min_limit && e.target.value <= value?.max_limit) {
                    formik.setFieldValue("amount", parseInt(value.amount));
                    inputValueClick(e);
                  } else if (
                    e.target.value > value?.max_limit ||
                    e.target.value < value?.min_limit
                  ) {
                    formik.setFieldValue("amount", parseInt(value.amount));
                    inputValueClick(e);
                  } else {
                    formik.setFieldValue("amount", parseInt(value.amount));
                    inputValueClick(e);
                  }
                }}
                key={index}
              >
                <img src={giftCardImg} width={20} height={18} alt="gift-card-img" />
                {parseInt(value?.amount)}
              </button>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default GiftCardsValue;
