import useFooter from "../../../logic/Footer";
import InnerHTML from "dangerously-set-html-content";

const Footer = () => {
  const { footer, script } = useFooter();

  return (
    <footer className="hnf-footer" role="contentinfo">
      {footer && footer && <InnerHTML html={footer && footer} />}
      {footer && script && <InnerHTML html={footer && script} />}
    </footer>
  );
};

export default Footer;
