import React, { useEffect } from "react";
import Style from "./Checkout.module.scss";
import CheckoutFor from "./CheckoutFor";
import OrderSummary from "./OrderSummary";
import CheckoutPayment from "./CheckoutPayment";
import { useStorePurchase } from "../../logic/useStorePurchase";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VatAlert from "./VatAlert";
import GiftCardSummary from "./GiftcardSummary";

const Checkout = () => {
  const { t } = useTranslation(["common"]);

  const navigate = useNavigate();

  const { slug, lang: langSlug } = useParams();

  const { fetchCardDetails, details } = useStorePurchase();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    fetchCardDetails(slug);
  }, []);
  const lang = localStorage.getItem("language_type");
  useEffect(() => {
    if (lang === "ar") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "rtl");
    } else if (lang === "en") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "ltr");
    } else {
      localStorage.setItem("language_type", "en");
    }
  }, []);

  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  return (
    <section className={Style.checkout_page}>
      <div className="giftCard_container">
        <div className={Style.checkout_container}>
          <h1 className={Style.checkout_page_title}>{t("CHECKOUT")}</h1>
          <div className={`row`}>
            <div className="col-12 col-md-5 col-xl-6">
              <div className={Style.checkout_left}>
                <CheckoutFor data={details} />
              </div>
            </div>
            <div className="col-12 col-md-7 col-xl-6">
              <div className={Style.checkout_right}>
                <GiftCardSummary data={details} />
                <VatAlert />
                <CheckoutPayment data={details} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Checkout;
