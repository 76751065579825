import useMenu from '../../../logic/Menu';
import InnerHTML from 'dangerously-set-html-content';
import Style from './Menu.module.scss';

const Menu = () => {
 const {header,style} = useMenu();
 
  return (
    <div className={Style.site_header}>
      {header && style && <InnerHTML html={header && style}/>}
      {header && header && <InnerHTML html={header && header}/>}
    </div>
  )
}

export default Menu